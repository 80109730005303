<template>
  <div class="min-w-0 w-full">
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step
          step="1"
          :rules="[() => (step > 1 ? validateApartment() : true)]"
          :complete="apartmentCheck"
          >Kohde</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step
          step="2"
          :rules="[() => (step > 2 ? validateInformation() : true)]"
          :complete="landLordCheck && tenantCheck"
          >Yhteystiedot</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step
          step="3"
          :rules="[() => (step > 3 ? validateLease() : true)]"
          :complete="leaseCheck"
          >Vuokra</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step
          step="4"
          :rules="[() => (step > 4 ? validateOthers() : true)]"
          :complete="othersCheck"
          >Muut</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step step="5">Yhteenveto</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <div align="right">
            <v-btn
              color="primary"
              @click="
                step = 2;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>

          <!-- Apartment -->
          <Apartment ref="apartment"></Apartment>

          <div align="right">
            <v-btn
              color="primary"
              @click="
                step = 2;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 1;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn
              color="primary"
              @click="
                step = 3;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>

          <!-- Landlord -->
          <Landlord ref="landlord"></Landlord>
          <v-divider></v-divider>
          <!-- Tenant -->
          <Tenant ref="tenant"></Tenant>

          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 1;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn
              color="primary"
              @click="
                step = 3;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 2;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn
              color="primary"
              @click="
                step = 4;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>

          <!-- Lease -->
          <Lease ref="lease"></Lease>

          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 2;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn
              color="primary"
              @click="
                step = 4;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 3;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn
              color="primary"
              @click="
                step = 5;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>

          <!-- OtherData -->
          <other-data ref="otherData"></other-data>

          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 3;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn
              color="primary"
              @click="
                step = 5;
                scrollToTop();
              "
              >Seuraava</v-btn
            >
          </div>
        </v-stepper-content>

        <v-stepper-content step="5">
          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 4;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
          </div>

          <!-- Summary -->
          <Summary ref="summary"></Summary>

          <div class="buttons-wrapper">
            <v-btn
              color="error"
              outlined
              @click="
                step = 4;
                scrollToTop();
              "
              >Takaisin</v-btn
            >
            <v-btn color="info" @click="submit()">Luo</v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import axiosMethods from "../../mixins/axios";
import Landlord from "../../components/RentalContract/Landlord";
import Tenant from "../../components/RentalContract/Tenant";
import Apartment from "../../components/RentalContract/Apartment";
import Lease from "../../components/RentalContract/Lease";
import { mapActions, mapState } from "vuex";

import OtherData from "../../components/RentalContract/OtherData";
import Summary from "../../components/RentalContract/Summary";
import mixins from "../../mixins/mixins";

export default {
  title: "Luo vuokrasopimus",

  components: {
    Landlord,
    Tenant,
    Apartment,
    Lease,
    OtherData,
    Summary,
  },

  mixins: [mixins],

  data() {
    return {
      step: 1,
      landLordCheck: false,
      tenantCheck: false,
      apartmentCheck: false,
      leaseCheck: false,
      othersCheck: false,
    };
  },

  async created() {
    // Delete old data
    this.$store.commit("deleteContract");
    // Set saveTenantInformation options to false on create
    this.$store.state.saveTenantInformation = false;

    // Get products
    await this.getInvoiceProducts("/api/v1/product/get-invoice-products");

    eventBus.$on("checklandlord", (boolean) => {
      this.landLordCheck = boolean;
    });
    eventBus.$on("checktenant", (boolean) => {
      this.tenantCheck = boolean;
    });
    eventBus.$on("checkapartment", (boolean) => {
      this.apartmentCheck = boolean;
    });
    eventBus.$on("checklease", (boolean) => {
      this.leaseCheck = boolean;
    });
    eventBus.$on("checkothers", (boolean) => {
      this.othersCheck = boolean;
    });

    this.$store.commit("updateLocalStorage");
  },

  computed: {
    ...mapState("contractmodel", ["contentError"]),
  },

  watch: {
    step(val) {
      if (val == 5) {
        this.$refs.summary.getRentalContractContent();
      }
    },
  },

  methods: {
    ...mapActions("product", ["getInvoiceProducts"]),

    validateApartment() {
      this.$refs.apartment.checkIfValid();

      if (this.apartmentCheck) return true;
      else return false;
    },

    validateInformation() {
      this.$refs.landlord.checkIfValid();
      this.$refs.tenant.checkIfValid();

      if (this.landLordCheck && this.tenantCheck) return true;
      else return false;
    },

    validateLease() {
      this.$refs.lease.checkIfValid();

      if (this.leaseCheck) {
        return true;
      } else {
        return false;
      }
    },

    validateOthers() {
      this.$refs.otherData.checkIfValid();

      if (this.othersCheck) {
        return true;
      } else {
        return false;
      }
    },

    async submit() {
      // Check if content is loaded to summary
      if (
        this.$refs.summary.modelContentLoaded == "" ||
        this.$refs.summary.modelContentLoaded == "<p></p>" ||
        this.contentError
      ) {
        return this.showPopup(
          'Lataa vuokrasopimuksen tiedot sopimuspohjan "Lataa"-napista tai luo uusi sopimuspohja ja varmista, että siinä on sisältöä ja se on oikein muotoiltu.',
          "error"
        );
      }

      if (
        this.landLordCheck &&
        this.tenantCheck &&
        this.apartmentCheck &&
        this.leaseCheck &&
        this.othersCheck
      ) {
        try {
          let contract = this.$store.getters.getContract;
          let saveTenantInformation = this.$store.state.saveTenantInformation;

          // Check lease dates
          if (contract.lease.leaseType == "Määräaikainen") {
            const startDate = new Date(contract.lease.startDate).getTime();
            const endDate = new Date(contract.lease.endDate).getTime();

            if (endDate <= startDate) {
              return this.showPopup(
                "Vuokrauksen päättymispäivä tulee olla suurempi kuin alkamispäivä",
                "error"
              );
            }
          }

          // Post
          const res = await axiosMethods.post("/api/v1/rental-contract/create/", {
            contract,
            saveTenantInformation,
          });

          if (typeof res.data.emailCount === "number" && res.data.emailCount > 0) {
            this.showPopup(
              `Samalla sähköpostilla löytyy ${res.data.emailCount} ${
                res.data.emailCount === 1 ? "muu vuokralainen" : "muuta vuokralaista"
              }. Tarkista, ettet vahingossa asettanut väärää email-osoitetta.`,
              "info"
            );
          }

          this.$store.commit("deleteContract");
          this.$router.push("/rental-contracts");

          this.showPopup("Vuokrasopimuksen luominen onnistui", "success");
        } catch (err) {
          this.showPopup("Vuokrasopimuksen luominen ei onnistunut. " + err, "error");
        }
      } else {
        this.showPopup(
          "Lomakkeen kaikki kentät eivät ole täytetty oikein. Tarkista kaikki pakolliset kentät.",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.buttons-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>
