var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"min-w-0 w-full"},[_c('v-stepper',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","rules":[function () { return (_vm.step > 1 ? _vm.validateApartment() : true); }],"complete":_vm.apartmentCheck}},[_vm._v("Kohde")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2","rules":[function () { return (_vm.step > 2 ? _vm.validateInformation() : true); }],"complete":_vm.landLordCheck && _vm.tenantCheck}},[_vm._v("Yhteystiedot")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3","rules":[function () { return (_vm.step > 3 ? _vm.validateLease() : true); }],"complete":_vm.leaseCheck}},[_vm._v("Vuokra")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"4","rules":[function () { return (_vm.step > 4 ? _vm.validateOthers() : true); }],"complete":_vm.othersCheck}},[_vm._v("Muut")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"5"}},[_vm._v("Yhteenveto")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('div',{attrs:{"align":"right"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.step = 2;
              _vm.scrollToTop();}}},[_vm._v("Seuraava")])],1),_c('Apartment',{ref:"apartment"}),_c('div',{attrs:{"align":"right"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.step = 2;
              _vm.scrollToTop();}}},[_vm._v("Seuraava")])],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.step = 1;
              _vm.scrollToTop();}}},[_vm._v("Takaisin")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.step = 3;
              _vm.scrollToTop();}}},[_vm._v("Seuraava")])],1),_c('Landlord',{ref:"landlord"}),_c('v-divider'),_c('Tenant',{ref:"tenant"}),_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.step = 1;
              _vm.scrollToTop();}}},[_vm._v("Takaisin")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.step = 3;
              _vm.scrollToTop();}}},[_vm._v("Seuraava")])],1)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.step = 2;
              _vm.scrollToTop();}}},[_vm._v("Takaisin")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.step = 4;
              _vm.scrollToTop();}}},[_vm._v("Seuraava")])],1),_c('Lease',{ref:"lease"}),_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.step = 2;
              _vm.scrollToTop();}}},[_vm._v("Takaisin")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.step = 4;
              _vm.scrollToTop();}}},[_vm._v("Seuraava")])],1)],1),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.step = 3;
              _vm.scrollToTop();}}},[_vm._v("Takaisin")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.step = 5;
              _vm.scrollToTop();}}},[_vm._v("Seuraava")])],1),_c('other-data',{ref:"otherData"}),_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.step = 3;
              _vm.scrollToTop();}}},[_vm._v("Takaisin")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.step = 5;
              _vm.scrollToTop();}}},[_vm._v("Seuraava")])],1)],1),_c('v-stepper-content',{attrs:{"step":"5"}},[_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.step = 4;
              _vm.scrollToTop();}}},[_vm._v("Takaisin")])],1),_c('Summary',{ref:"summary"}),_c('div',{staticClass:"buttons-wrapper"},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.step = 4;
              _vm.scrollToTop();}}},[_vm._v("Takaisin")]),_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Luo")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }